<template>
	<v-card  dark class="backBlueDarken backBlueLight--text my-3" :loading="loading" outlined elevation="3">
		<div class="text-center">
			<v-text-field
				dense
				label="Simcard Number"
				filled
				v-model="simcardField"
				counter="20"
				@change="changeInput"
				@input="countInput"
				type="number"
			/>
			<v-alert dense type="success" v-if="successful"> Update successfully</v-alert>
			<v-alert dense type="error" v-if="error"> Invalid SIMCARD !!</v-alert>
		</div>
	</v-card>
</template>

<script>
import { dbFt } from '@/main';
import formatMixin from '@/mixins/formatMixin'
export default {

	data: () => ({
		simcardField: null,
		loading: false,
		imei: '',
		successful: false,
		error: false
	}),
	mounted() {
		this.getData()
	},
	methods: {
		countInput() {
			if (this.simcardField.length == 20){
				this.updatedSimcard();
			}
		},
		changeInput() {
			if (this.simcardField.length != 20 ) {
				this.error = true
				setTimeout(() => this.error = false, 3000);
				return
			}
			this.updatedSimcard();
		},
		async getData() {
			let me = this
			this.imei = this.$route.params.imei
			let ref = dbFt.collection('devices').doc(me.imei)
			await ref.get()
				.then(doc => {
					if (doc.exists) {
						me.simcardField = doc.data().simcard
					}
				})
				.catch(err => {
					console.log('Error getting document', err);
				});
		},
		async updatedSimcard() {
			let me = this
			try {
				await dbFt.collection('devices').doc(me.imei).update({ simcard: me.simcardField })
				me.successful = true
				setTimeout(() => me.successful = false, 3000);
			} catch (error) {
				console.log(error)
			}
		},
	}
}
</script>

<style lang="scss" scoped></style>